import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={8}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 8</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p>In Lesson 1, you used a rubric to evaluate information about website features. Now, you will continue working with the rubric.</p>
              <p>Get the rubrics you started in Lesson 1. Notice in the "Lesson 2" category that there are two entries in the rubric to get you started. What criteria do think should be added to the rubric? Work with 1–2 teammates to discuss ideas for other criteria that would be appropriate for this category.</p>
              <p>Think about what you learned from reading about clinical trials to help you. The handout Evaluating the Clinical Trials may also help.</p>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Have students retrieve the copies of the rubric that they started using in Lesson 1. Students can work with their team members to think about criteria to add to the "Lesson 2" category of the rubric based on what they learned about accuracy of information in this lesson. Students do not need to have an entry for all the boxes in the chart. If helpful, remind students to write their criteria in the form of a statement rather than a question and to phrase it in a way that the optimal answer would lead to a higher score.</p>
              <p>The goal is for students to develop their own rubric and to start making decisions about what is important to include.</p>
              <p>The rubric lists two criteria to help students get started. Potential entries on the rubric related to accuracy of information include the following:</p>

              <UnorderedList>
                <li>The information or claim is based on recent scientific studies.</li>
                <li>The treatment, medication, or intervention has been appropriately tested (through well-designed clinical trials).</li>
                <li>Information is provided about whom the intervention applies to (e.g., men, women, racial/ethnic groups, ages).</li>
                <li>Any conflict-of-interest or financial incentive is disclosed.</li>
              </UnorderedList>

              <p>Students may find it challenging to write criteria on the rubric. If more appropriate for your students, you can write one or two criteria statements as a class exercise before having students work in teams. You may also want to remind students that they are developing this rubric as a tool to give to someone else who does not know how to evaluate a website.</p>
              <p>Encourage students (both in this lesson and later) to view the rubric as something that is dynamic and can be changed later if they think of other things to add or decide that something should be revised.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
